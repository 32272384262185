import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem("auth_token"); // you probably want to store it in localStorage or something
    if (!token) {
      return next.handle(req);
    }
    const req1 = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`)
    });
    return next.handle(req1);
    /*.pipe(
      switchMap((event) => {
        const keepaliveTimer = timer(this.keepaliveInterval);

        return keepaliveTimer.pipe(
          switchMap(() => next.handle(req1))
        );
      })
    );
    */
  }

  private readonly keepaliveInterval = 30000; // 30 seconds




}
