import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class KeepaliveInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
     const keepaliveInterval = 3600000; // 60 minutes

     return timer(0, keepaliveInterval).pipe(
      switchMap(() => {
         const keepaliveRequest = request.clone();
         return next.handle(keepaliveRequest);
      })
    );
  }
}
