<div class="header-container" >

  <div>
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
</div>
<div class="logo-container" *ngIf="isLogoRequired" >
    <a class="logo" href="#" (click)="navigateHome()" >
      <img src="../../../../assets/images/logo/naturals/logo.png" title="Online EasyBill Application" width="170" height="60">
      </a>
  </div>
</div>
<!--
<div class theme-container *ngIf!="IsUserProfile">
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" placeholder="Select Theme" status="primary">
   <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
 </nb-select>
 </div> -->
<div class="header-container" *ngIf="IsUserProfile">
  <div>
    <label font-size="10px" color>{{headerName}}</label>
    </div>
  <nb-actions size="small">

    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture"
               nbContextMenuTag="my-context-menu">
      </nb-user>
    </nb-action>
  </nb-actions>
<!--
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" placeholder="Select Theme" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
-->
</div>
