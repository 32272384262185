export const environment = {
  production: true,
//  BaseAPI: "http://localhost:8080/api/v1/",
  BaseAPI: "http://www.myeasy2bill.com:8080/api/v1/",
 // BaseAPI: "http://ec2-35-154-177-143.ap-south-1.compute.amazonaws.com:8080/api/v1/",

  reportAPI: {
    "reportEndPoint": "salesReport",
  },
  ProductAPI: {
    "productEndPoint": "products",
  },
  customerAPI: {
    "customerEndPoint": "customers",
  },
  customerMembershipAPI: {
    "customerMembershipEndPoint": "customerMembershipMap",
  },
  employeeAPI: {
    "employeeEndPoint": "employee",
  },
  addressAPI: {
    "addressEndPoint": "address",
  },

  discountAPI: {
    "discountEndPoint": "discount",
  },

  genderAPI: {
    "genderEndPoint": "gender",
  },

  roleAPI: {
    "roleEndPoint": "roles",
  },

  salesReportAPI: {
    "salesEndPoint": "salesReport",
  },

  paymentModeAPI: {
    "paymentmodeEndPoint": "paymentMode",
  },

  invoiceAPI: {
    "invoiceEndPoint": "invoice",
  },

  invoicesAPI: {
    "invoicesEndPoint": "invoices",
  },

  invoiceReportAPI: {
    "invoiceReportEndPoint": "invoiceReport",
  },
  productInvoiceAPI: {
    "productInvoiceEndPoint": "productInvoiceMap",
  },

  serviceInvoiceAPI: {
    "serviceInvoiceEndPoint": "servicesInvoiceMap",
  },

  productCategoryAPI: {
    "productCategoryEndPoint": "productCategory",
  },
  serviceCategoryAPI: {
    "serviceCategoryEndPoint": "serviceCategory",
  },
  supplierAPI: {
    "supplierEndPoint": "supplier",
  },

  taxAPI: {
    "taxEndPoint": "tax",
  },
  taxgroupAPI: {
    "taxgroupEndPoint": "taxgroup",
  },
  EmployeeAPI: {
    "EmployeeEndPoint": "employee",
  },
  ExpenseAPI: {
    "ExpenseEndPoint": "expenses",
  },
  serviceAPI: {
    "serviceEndPoint": "services",
  },
  settingsAPI: {
    "settingsEndPoint": "masterconfig",
  },
  stateAPI: {
    "stateEndPoint": "state",
  },
  countryAPI: {
    "countryEndPoint": "country",
  },

  authAPI:{
    "authEndPoint" : "api/auth"
  },
  userAPI: {
    "userEndPoint": "users",
  },

  membershipAPI: {
    "membershipEndPoint": "membership",
  },

  branchAPI: {
    "branchEndPoint": "branch",
  },

  brandAPI: {
    "brandEndPoint": "brands",
  },

  brandCategoryAPI: {
    "brandCategoryEndPoint": "brandCategory",
  },
  branch: {
    "id": 1,
    "Name": "Karur bus stand",
  },

  Users: [{
    Identity: "superadm",
    Name: "superadm",
    Password: "superadm123",
    Role: "ROLE_SUPERADMIN"
  }]
};
