import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique'
})
export class UniquePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    
        // Remove the duplicate elements (this will remove duplicates
        let uniqueArray = value.filter(function (el, index, array) { 
          return array.indexOf (el) == index;
        });
    
      return uniqueArray;   } 
    

  

}
