import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  homePage: boolean = true;
  @Input() IsUserProfile: boolean = false;
  user: any;
  themes = [
    {
      value: 'default',
      name: 'SelectTheme',
    },
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Log out' }];
  brandName: any;
  headerName:any;
  isLogoRequired: boolean = false;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private route: Router) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    var userDetail = JSON.parse(localStorage.getItem("user"));
    var brand = JSON.parse(localStorage.getItem("brand"));
    var branch = JSON.parse(localStorage.getItem("branch"));

    if (userDetail != null) {
      this.user = {
        name: userDetail.Name,
        picture: '../../../../assets/images/users/user3.jpg',
        role: userDetail.roles[0]

      };
      if(this.user.role =="ROLE_USER" || this.user.role =="ROLE_BRANCHADMIN" ){
        this.headerName= "You are managing a branch -"+branch.branchId+"."+branch.branchName+"."+branch.branchNameExtn;
      }else if(this.user.role =="ROLE_BRANDADMIN"){
        this.headerName= "You are managing a brand "+brand.brandName;

      }else if(this.user.role=="ROLE_SUPERADMIN"){
        this.headerName= "You are managing EasyBill Application";

      }
      //alert(this.branchName);
      this.IsUserProfile = true;
      this.brandName = brand == null ? null : brand.brandName ;
      this.isLogoRequired = brand == null ? null : brand.logoEnabled ;
      this.homePage=false;
    }

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
          if(title != null && title == "Log out"){
            localStorage.clear();
            this.route.navigateByUrl("common/login");
          }
      });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
